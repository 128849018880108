<template>
  <div>
    <div class="widget-container">
      <DxForm id="form" 
            :form-data="formData" 
            label-mode='outside' 
            :read-only='false' 
            :show-colon-after-label='true' 
            label-location='top' 
            :min-col-width='300' 
            :col-count='2' 
            ref="formMaster">

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1" />

        <DxGroupItem caption="Κατάσταση Πελάτη" :col-span="5" :col-count="5">
          <DxSimpleItem data-field="active_ind" editor-type="dxCheckBox">
            <DxLabel text="Ενεργός" />
          </DxSimpleItem>

          <DxSimpleItem data-field="client_rank_id" :is-required="true" editor-type="dxLookup" :editor-options="{
                      dataSource: LookupCustomDS_ow_client_ranks,
                      searchEnabled: false,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                      dropDownOptions: {
                          hideOnOutsideClick: true,
                          showTitle: false
                      }
                  }">
            <DxLabel text="Αξιολόγηση" />
          </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Στοιχεία Πελάτη" :col-span="5" :col-count="5">
          <!--
              <DxSimpleItem data-field="id"/>
                <DxSimpleItem
                    data-field="id"
                >
                <DxLabel text="id"/>
              </DxSimpleItem>
          -->

          <DxSimpleItem data-field="name" :is-required="true">
            <DxLabel text="Επωνυμία" />
          </DxSimpleItem>

          <DxSimpleItem data-field="client_category_id" :is-required="true" editor-type="dxLookup" :editor-options="{
                      dataSource: LookupCustomDS_ow_clients_categories,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                      dropDownOptions: {
                        hideOnOutsideClick: true,
                        showTitle: false
                      }
                  }">
            <DxLabel text="Κατηγορία Πελάτη" />
          </DxSimpleItem>

          <DxSimpleItem data-field="clients_sources_id" :is-required="true" editor-type="dxLookup" :editor-options="{
                      dataSource: LookupCustomDS_ow_clients_sources,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                      dropDownOptions: {
                        hideOnOutsideClick: true,
                        showTitle: false
                      }
                  }">
            <DxLabel text="Πηγή Πελάτη" />
          </DxSimpleItem>

          <DxSimpleItem data-field="tax_no">
            <DxLabel text="Α.Φ.Μ." />
          </DxSimpleItem>

          <DxSimpleItem data-field="tax_office">
            <DxLabel text="Δ.Ο.Υ." />
          </DxSimpleItem>

        </DxGroupItem>
        <DxGroupItem caption="" :col-span="2" :col-count="2">

          <DxSimpleItem data-field="comments" editor-type="dxTextArea" :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}">
            <DxLabel :visible="true" text="Σχόλια" />
          </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Διεύθυνση Πελάτη" :col-span="4" :col-count="4">

          <DxSimpleItem data-field="address_street" :is-required="true">
            <DxLabel text="Διεύθυνση Οδός" />
          </DxSimpleItem>

          <DxSimpleItem data-field="address_city" :is-required="true">
            <DxLabel text="Πόλη" />
          </DxSimpleItem>

          <DxSimpleItem data-field="address_postal_code" :is-required="true">
            <DxLabel text="Τ.Κ." />
          </DxSimpleItem>

          <DxSimpleItem data-field="email">
            <DxLabel text="Email" />
          </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem caption="Στοιχεία Ραντεβού" :col-span="3" :col-count="3">
          <DxSimpleItem data-field="work_type_id" editor-type="dxSelectBox" :is-required="true" :editor-options="{
                        dataSource: LookupCustomDS_ow_work_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id',
                        sort: 'name',
                        showClearButton: true,
                      }">
            <DxLabel text="Είδος Εργασίας" />
          </DxSimpleItem>

          <DxSimpleItem data-field="subscription_amount" editor-type="dxNumberBox" :is-required="true" :editor-options="editorNumberBoxOptions">
            <DxLabel text="Κόστος Εργασίας" />
          </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem :col-span="4" :col-count="4">
          <DxSimpleItem data-field="appointments_frequency" editor-type="dxSelectBox" :editor-options="{
                            dataSource: OParameters.appointmentsFrequencies,
                            searchEnabled: true,
                            displayExpr: 'name',
                            valueExpr: 'value',
                            sort: 'name',
                            showClearButton: true,
                            onValueChanged: appointmentFrequencyChange
                        }">
            <DxLabel text="Συχνότητα Εργασιών / Έτος" />

          </DxSimpleItem>

          <DxSimpleItem template="grid-template">
            <DxLabel text="Μήνες" />
          </DxSimpleItem>

          <DxSimpleItem data-field="first_appointment_date" :is-required="true" editor-type="dxDateBox" :editor-options="datetimeBoxEditorOptions">
            <DxLabel text="Ημερομηνία & Ώρα 1ου Ραντεβού" />
          </DxSimpleItem>

          <DxSimpleItem data-field="recreate_appointments_ind" editor-type="dxCheckBox">
            <DxLabel text="Επαναδημιουργία των Ραντεβού" />
          </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Στοιχεία Επικοινωνίας" :col-span="3" :col-count="3">

          <DxSimpleItem data-field="responsible_person_name1">
            <DxLabel text="Ονοματεπώνυμο Υπευθύνου" />
          </DxSimpleItem>

          <DxSimpleItem data-field="mobile_phone_1">
            <DxLabel text="Κινητό 1" />
          </DxSimpleItem>

          <DxSimpleItem data-field="phone_1">
            <DxLabel text="Τηλέφωνο 1" />
          </DxSimpleItem>

          <DxSimpleItem data-field="responsible_person_name2">
            <DxLabel text="Ονοματεπώνυμο Υπευθύνου 2" />
          </DxSimpleItem>

          <DxSimpleItem data-field="mobile_phone_2">
            <DxLabel text="Κινητό 2" />
          </DxSimpleItem>

        </DxGroupItem>

        <template #grid-template>
          <DxDropDownBox 
            ref="dropdownRef" 
            data-field="appointments_months" 
            v-model:value="selectedMonths" 
            :defer-rendering="false" 
            :show-clear-button="true" 
            :data-source="OParameters.months" 
            placeholder="Αυτόματη επιλογή μηνών βάση συχνότητας" 
            :is-required="true" 
            value-expr="id" 
            display-expr="name">

            <DxValidator ref="dropdownValidator">
              <DxCustomRule 
                  :validation-callback="validateDropDown" 
                  message="Ο αριθμός των επιλεγμένων μηνών πρέπει να συμφωνεί με την συχνότητα" />
            </DxValidator>

            <template #content="{ /*data*/ }">

              <DxDataGrid 
                  key-expr="id" 
                  :data-source="OParameters.months" 
                  ref="dataGridMonthsRef" 
                  :columns="columns" 
                  :allow-column-reordering="false" 
                  :allow-column-resizing="false" 
                  :column-hiding-enabled="false" 
                  :column-auto-width="true" 
                  :show-borders="true" 
                  :show-column-lines="true" 
                  :show-row-lines="true" 
                  :row-alternation-enabled="true" 
                  :focused-row-enabled="true" 
                  :word-wrap-enabled="true" 
                  :scrolling="{ mode: 'standard' }" 
                  v-model:selected-row-keys="selectedMonths" 
                  height="100%" 
                  @selection-changed="onSelectionChanged">

                <DxColumn 
                  data-field="name" 
                  caption="Μήνας">
                </DxColumn>

                <DxColumn 
                  data-field="id" 
                  :visible="false" 
                  sort-order="asc">
                </DxColumn>

                <DxFilterRow :visible="false" />
                <DxSelection mode="multiple" />
                <DxScrolling mode="virtual" />
              </DxDataGrid>
            </template>

          </DxDropDownBox>
        </template>

      </DxForm>

      <div id="buttons">
        <DxToolbar>
          <DxItem location="after" widget="dxButton" @click="onClickSave" :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }">
          </DxItem>
          <DxItem location="after" widget="dxButton" @click="onClickClose" :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }">
          </DxItem>
        </DxToolbar>
      </div>
    </div>
  </div>
</template>

<script setup>

const emit = defineEmits(['closePopupEvent'])


import OParameters from '../es-globals';

import CustomStore from 'devextreme/data/custom_store';
//import DataSource from 'devextreme/data/data_source'
import {ref, watch, /*, reactive, computed, onMounted*/} from 'vue';

import 'whatwg-fetch';

import {
    DxForm, 
    DxLabel,
    DxSimpleItem, 
    //DxRequiredRule,
    DxGroupItem,
    DxColCountByScreen,
    //DxValidator, 
    //DxCustomRule
} from 'devextreme-vue/form'

import DxValidator, {
  DxCustomRule
} from 'devextreme-vue/validator';

import DxDropDownBox from "devextreme-vue/drop-down-box";
import {DxDataGrid, DxColumn, DxSelection, DxItem, DxFilterRow, DxScrolling,} from 'devextreme-vue/data-grid'
//import DxNumberBox from 'devextreme-vue/number-box';
import DxToolbar from 'devextreme-vue/toolbar'

//Form
const URL_SERVICE = OParameters.Host.URI + 'ow_clients';

const URL_SERVICE_LOOKUP_ow_clients_categories = OParameters.Host.URI + 'ow_clients_categories';
//const URL_SERVICE_LOOKUP_ow_client_appointments_months = OParameters.Host.URI + 'ow_client_appointments_months';

const URL_SERVICE_LOOKUP_ow_clients_sources = OParameters.Host.URI + 'ow_clients_sources';
//const URL_SERVICE_LOOKUP_ow_appointment_status = OParameters.Host.URI + 'ow_appointment_status';

const URL_SERVICE_LOOKUP_ow_work_types = OParameters.Host.URI + 'ow_work_types';
//const URL_SERVICE_LOOKUP_ow_view_technicians = OParameters.Host.URI + 'ow_view_technicians';
const URL_SERVICE_LOOKUP_ow_client_ranks = OParameters.Host.URI + 'ow_client_ranks';


const datetimeBoxEditorOptions = {
    //pickerType: 'rollers',
    type: 'datetime',
    pickerType: 'rollers',
    displayFormat: 'dd/MM/yyyy HH:mm',
    dateSerializationFormat: 'yyyy-MM-dd HH:mm:ss',
    invalidDateMessage: 'Λάθος Ημερομηνία',
    showClearButton: true,
    //min: new Date(),
    max: new Date(2030, 11, 31),
}

const editorNumberBoxOptions = {
    placeholder: 'Εισάγετε ποσό...',
    format: OParameters.methods.formatCurrencyNumber,
    showSpinButtons: true,
    step: 0.01
}

var selectedMonths = []

const formMaster = ref(null)
const dataGridMonthsRef = ref(null)
const formData = ref({})

/*
const gridWidth = computed(() => '100%') // set the width to 100%
const maxWidth = computed(() => '800px') // set the max-width to 800px
*/

var isGridValidated = true
var isMonthsGridValidated = true
var isInsertMode = false

    watch(formData.value.id, (newValue, oldValue) => {
      console.log('Watch client-id-value-changed')
      emit('client-id-value-changed', newValue, oldValue)
      loadFormData(newValue)
    })

    watch(selectedMonths, (newValue) => {
        console.log('Watch Selected Months:', newValue);
    })

    const LookupCustomDS_ow_clients_categories = new CustomStore({
          key: 'id', 
          loadMode: 'raw',
          load: () => sendRequest(URL_SERVICE_LOOKUP_ow_clients_categories + '?order=name'),
          byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_clients_categories + "/" + encodeURIComponent(key)),
    })

    const LookupCustomDS_ow_client_ranks = new CustomStore({
          key: 'id', 
          loadMode: 'raw',
          load: () => sendRequest(URL_SERVICE_LOOKUP_ow_client_ranks + '?order=name'),
          byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_client_ranks + "/" + encodeURIComponent(key)),
    })

    const LookupCustomDS_ow_clients_sources = new CustomStore({
          key: 'id', 
          loadMode: 'raw',
          load: () => sendRequest(URL_SERVICE_LOOKUP_ow_clients_sources + '?order=name'),
          byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_clients_sources + "/" + encodeURIComponent(key)),
    })

    const LookupCustomDS_ow_work_types = new CustomStore({
          key: 'id',
          loadMode: 'raw',
          load: () => sendRequest(URL_SERVICE_LOOKUP_ow_work_types + '?order=name'),
          byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_work_types + "/" + encodeURIComponent(key)),
    })

    const CustomStoreMain = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE),
        insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
        update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
        remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
    })

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
              return fetch(url, {
                  method,
                  credentials: 'include',
              })
              .then(response => response.json())
              .then(json => {
                  return json.records;
              })
          }
          return fetch(url, {
              method,
              body: JSON.stringify(values),
              headers: {
                  'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              },
              credentials: 'include',
          }).then((result) => {
              if (result.ok) {
                  return result.text().then((text) => text && JSON.parse(text));
              }
              return result.json().then((json) => {
                  throw json.Message;
              });
          });
    }

    const sendRequestByKey = (url, method = 'GET') => {
        //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        return fetch(url, {
                  method,
                  headers: {
                      'Accept': 'application/json',
                  },
                  credentials: 'include',
              })
              .then(response => response.json())
    }

    const saveData = async () => {
        // console.log('Save data');
          return await CustomStoreMain.update(formData.value.id, formData.value);
    }

    const insertData = async () => {
        // console.log('Insert data');
         return await CustomStoreMain.insert(formData.value);
    }

    /*
    onMounted = async () => {
        if (props.client_id != null) formData = await CustomStoreMain.byKey(props.client_id)
    }
    */

    const loadFormData = async (id) => {
        // nf 28/6/2023 
        // init disposal value in order to invoke onchange event which disables dispose field        
        formData.value = {}
        if (id != null) {
            isInsertMode = false
            formData.value = await CustomStoreMain.byKey(id)

            selectedMonths = ref(formData.value.appointments_months?.split(',').map(value => parseInt(value, 10)) || [])

            //selectedMonths = ref(selectedMonths) // nf in order to be possible to update grid keys var need to be reactive, BUT after initial filling ?!?!?!?
        } else {
            isInsertMode = true
            formData.value.active_ind = 1
            formData.value.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ')
        }
    }

    const onClickSave = async () => {
      
        const formValidator = formMaster.value.instance.validate()
        isGridValidated = true
        let formSaved = false

        if (formValidator.isValid && isMonthsGridValidated) {           
            formData.value.appointments_months = selectedMonths.value?.join(',') || ''

            if (formData.value.appointments_months == 'NaN') {
              formData.value.appointments_months = ''
            }
            
            if (!isInsertMode) {
                await saveData()
                formSaved = true
            } else {
                await insertData()
                formSaved = true
            }
        }

        if (isGridValidated && formSaved && isMonthsGridValidated) emit('closePopupEvent', true)

    }

    const onClickClose = () => {
        emit('closePopupEvent', true)
    }

    const onSelectionChanged = (e) => {
        if (e.selectedRowKeys.length > formData.value.appointments_frequency) {
          // Revert to the first 4 selected items
          selectedMonths.value = e.selectedRowKeys.slice(0, formData.value.appointments_frequency)
        } else {
          // Otherwise, update the selectedMonths array with current selection
          selectedMonths.value = e.selectedRowKeys
        }
    }

    const appointmentFrequencyChange = (/*e*/) => {
        dataGridMonthsRef.value?.instance.clearSelection()
    }

    const validateDropDown = (/*e*/) => {
        const selectedKeysCount = dataGridMonthsRef.value?.instance.getSelectedRowKeys()?.length || 0
        if (formData.value.appointments_frequency == null || selectedKeysCount === 0) {
          isMonthsGridValidated = true
        } else {
          isMonthsGridValidated = formData.value.appointments_frequency === selectedKeysCount
        }
        return isMonthsGridValidated
    }

    defineExpose({
      loadFormData
    });

</script>

<style lang="scss">

/* Target the header titles in the DxDataGrid */
.dx-datagrid-headers .dx-datagrid-text-content {
  font-weight: bold;
}

/* Target the labels in the DxForm */
.dx-field-item-label-text {
  font-weight: bold;
}

.ow-list-filters {
    display: flex;
    background: #ffffff;
    padding: 8px 8px 0 8px;
    flex-wrap: wrap;
    gap: 10px; /* Adjust as needed for spacing between items */
}

.dx-toolbar-label .dx-toolbar-item-content > div {
    width: 200px;
}

.filter-label {
    font-weight: bold;
    align-content: center;
    padding: 0 5px 0 5px;
}

.ow-list-filter {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Space between items when stacked */
  
  font-size: 12px;
}

.filter-label {
  margin-right: 5px; /* Space between label and input */
}

</style>

<style scoped>


#form {
 margin-top: 25px;
}
.caption {
  font-size: 18px;
  font-weight: 500;
}
.option {
  margin-top: 10px;
}
.dx-fieldset-header {
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 4px;
}

</style>

<template>
  <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
    <p>{{ data }}</p>


    <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"

        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        title="Διαχείριση Χρήστη"
        :width="'90%'" 
        :height="'90%'"

        v-model:visible="isPopupVisible"
        titleTemplate="title"
        deferRendering="true"
        :animation="{
                      show: {
                          type: 'pop',
                          duration: 800,
                          from: {
                              scale: 0.90
                          }
                      }, 
                    }"
    >
        <DxScrollView height="100%" width="100%" ref="FormScrollViewRef">
            <UsersForm
                ref="usersForm"
                :user_id="selectedUserID" 
                @closePopupEvent="onClosePopupEmit">
            </UsersForm>
        </DxScrollView>

    </DxPopup>


    <DxDataGrid
        :key-expr="id"
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :word-wrap-enabled="true"
        ref="dataGrid"
        @exporting="onExporting"
        @initNewRow="initNewRow"
    >
        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxGroupPanel :visible="false"/>

      <DxSorting mode="multiple"/>

      <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="false"

            :texts= "{
                      addRow: 'Προσθήκη',
                      deleteRow: 'Διαγραφή',
                      editRow:'Επεξεργασία',
                      saveAllChanges:'Αποθήκευση Όλων',
                      cancelAllChanges:'Ακύρωση Όλων'
                  }"
          mode="row"
      >
      </DxEditing>


      <DxToolbar>
            <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                onCustomInitNewRow()
                            }
                        }"

                />
            <DxItem name="exportButton" 
                :options="{
                        hint: 'Εξαγωγή δεδομένων'
                    }"
            />
      </DxToolbar>


      <DxColumn
          data-field="name"
          caption="Ονοματεπώνυμο"
          sort-order="asc"
      >
          <DxRequiredRule/>
      </DxColumn>

      <DxColumn
          data-field="username"
          caption="Όνομα Χρήστη"
      >
          <DxRequiredRule/>
      </DxColumn>

      <DxColumn
          data-field="block_user"
          caption="Αποκλεισμός Χρήστη"
          data-type="boolean"
          width="200"
      >
      </DxColumn>

      <DxColumn
          data-field="date_time"
          caption="Επιπλέον Πληροφορίες"
          :visible="false"
      >
      </DxColumn>

      <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
      </DxColumn>

      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />

  </DxDataGrid>
</div>
</template>

<script setup>
    import {
        DxDataGrid,
        DxColumn,
        DxEditing, 
        DxPaging, 
        DxPager,
        DxGroupPanel,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxExport,
        DxRequiredRule,
        DxToolbar,
        DxItem,
        DxButton
    } from 'devextreme-vue/data-grid'

    import 'whatwg-fetch'
    import CustomStore from 'devextreme/data/custom_store'
    import OParameters from '../es-globals'

    import DxPopup from 'devextreme-vue/popup'
    import DxScrollView from 'devextreme-vue/scroll-view'

    //import { exportDataGrid } from 'devextreme/pdf_exporter';
    import UsersForm from './ow-users-form.vue'

    import { ref, /*reactive, computed, onMounted */} from 'vue'

    const dataGrid = ref(null)
    const FormScrollViewRef = ref(null)

    const selectedUserID = ref(null)
    const usersForm = ref(null)
    const isPopupVisible = ref(false)


    const URL_SERVICE = OParameters.Host.URI + 'ow_users'

    const CustomStoreMain= new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE),
        //insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
        //update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
        //remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
    })

    const title= 'Χρήστες'
    const pageSizes= [5, 10, 20]

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
            return fetch(url, {
                method,
                credentials: 'include',
            })
            .then((response) => response.json()
            .then((json) => {
                if (response.ok) return json.records;
                throw json.Message;
            }));
        }
        return fetch(url, {
            method,
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            credentials: 'include',
        }).then((result) => {
            if (result.ok) {
                return result.text().then((text) => text && JSON.parse(text));
            }
            return result.json().then((json) => {
                throw json.Message;
            });
        });
    }

    const onExporting = (e) =>{
        OParameters.methods.exportDataGridToPdfExcel(e, this.title)
    }

    const onClosePopupEmit = () => {
        FormScrollViewRef.value.instance.scrollTo(0);
        isPopupVisible.value = false
        dataGrid.value?.instance?.refresh()
    }

    const onCustomInitNewRow = (/*e*/) => {
        // e.data.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ');
        selectedUserID.value = null
        usersForm.value.loadFormData(selectedUserID.value)
        isPopupVisible.value = true
    }

    const onCustomRowEdit = (e) => {
        console.log('onCustomRowEdit')
        selectedUserID.value = e.row.key
        usersForm.value.loadFormData(selectedUserID.value)
        isPopupVisible.value = true
    }

</script>
<style lang="scss">
</style>
